import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3956fee6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "pageList",
  class: "CpmList"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_ListEngine = _resolveComponent("ListEngine")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ListEngine, {
      pageList: _ctx.pageList,
      ref: "pageListRef",
      formatPageInfo: _ctx.formatPageInfo
    }, {
      queryParams: _withCtx(() => [
        _createVNode(_component_el_row, { class: "myRow" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('cpm.queryParam.brandId')) + "：", 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  ref: el=>_ctx.refMap.set('brandId',el),
                  modelValue: _ctx.pageList.queryParam.brandId,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pageList.queryParam.brandId) = $event)),
                  placeholder: _ctx.$t('cpm.queryParam.brandId_placeholder'),
                  clearable: "",
                  style: {"width":"100%"},
                  onChange: _cache[1] || (_cache[1] = (val)=>{_ctx.selectOnChange(val,'brandId')})
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comboSelect({comboId:'brandId'}), (item) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: item.value,
                        label: item.label,
                        value: item.value
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('cpm.queryParam.cxId')) + "：", 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  ref: el=>_ctx.refMap.set('cxId',el),
                  modelValue: _ctx.pageList.queryParam.cxId,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.pageList.queryParam.cxId) = $event)),
                  placeholder: _ctx.$t('cpm.queryParam.cxId_placeholder'),
                  clearable: "",
                  style: {"width":"100%"}
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comboSelect({comboId:'cxId'}), (item) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: item.value,
                        label: item.label,
                        value: item.value
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, { class: "myRow" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('cpm.queryParam.cpm')) + "：", 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  onInput: _cache[3] || (_cache[3] = e => _ctx.pageList.queryParam.cpm = _ctx.valid(e)),
                  placeholder: _ctx.$t('cpm.queryParam.cpm_placeholder'),
                  modelValue: _ctx.pageList.queryParam.cpm,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.pageList.queryParam.cpm) = $event)),
                  clearable: ""
                }, null, 8, ["placeholder", "modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('cpm.queryParam.color')) + "：", 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  onInput: _cache[5] || (_cache[5] = e => _ctx.pageList.queryParam.color = _ctx.valid(e)),
                  placeholder: _ctx.$t('cpm.queryParam.color_placeholder'),
                  modelValue: _ctx.pageList.queryParam.color,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.pageList.queryParam.color) = $event)),
                  clearable: ""
                }, null, 8, ["placeholder", "modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, { class: "myRow" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('cpm.queryParam.flag')) + "：", 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  ref: el=>_ctx.refMap.set('flag',el),
                  modelValue: _ctx.pageList.queryParam.flag,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.pageList.queryParam.flag) = $event)),
                  placeholder: _ctx.$t('cpm.queryParam.flag_placeholder'),
                  clearable: "",
                  style: {"width":"100%"}
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comboSelect({comboId:'flag'}), (item) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: item.value,
                        label: item.label,
                        value: item.value
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      extendSlot: _withCtx(() => []),
      tbCols: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "F_BRAND_ID",
          label: _ctx.$t('cpm.columns.F_BRAND_ID')
        }, null, 8, ["label"]),
        _createVNode(_component_el_table_column, {
          prop: "F_CX_ID",
          label: _ctx.$t('cpm.columns.F_CX_ID')
        }, null, 8, ["label"]),
        _createVNode(_component_el_table_column, {
          prop: "F_CPM",
          label: _ctx.$t('cpm.columns.F_CPM')
        }, null, 8, ["label"]),
        _createVNode(_component_el_table_column, {
          prop: "F_COLOR",
          label: _ctx.$t('cpm.columns.F_COLOR')
        }, null, 8, ["label"]),
        _createVNode(_component_el_table_column, {
          prop: "F_FLAG",
          label: _ctx.$t('cpm.columns.F_FLAG')
        }, null, 8, ["label"]),
        _createVNode(_component_el_table_column, {
          prop: "F_CXGGH",
          label: "车型公告号"
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_COUNTRY_ID",
          label: "国家"
        })
      ]),
      _: 1
    }, 8, ["pageList", "formatPageInfo"])
  ]))
}